import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button, Card, Text, Container, HStack, Flex, Box } from '@chakra-ui/react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';

import SnippetNode from './SnippetNode';
import InstructionNode from './InstructionNode';

const nodeTypes = {
  snippet: SnippetNode,
  instruction: InstructionNode,
};

const FilterBanner = ({ onClear }) => {
  return (
    <Card borderTop={'1px solid #cfcfcf'} borderRadius={0} boxShadow={'none'}>
      <Container>
        <HStack p={2} justify={'space-between'}>
          <Text>Only select nodes are being shown</Text>
          <Button onClick={() => onClear()}>Remove Filter</Button>
        </HStack>
      </Container>
    </Card>
  );
};

const Flow = ({ caseID, filteredNodeIDs, clearNodeFilter, goToDoc }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(true);
  const [newNodeId, setNewNodeId] = useState(null);

  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    const fetchSnippets = async () => {
      try {
        const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_snippets`;
        const response = await axios.get(url, {
          params: {
            case_id: caseID,
          },
        });
        const data = response.data;

        const formattedNodes = data.map(node => ({
          id: node._id.$oid,
          type: node.node_type || 'snippet',
          position: { x: node.x, y: node.y },
          data: {
            ...node,
            caseID: caseID,
            isFiltered: filteredNodeIDs
              ? filteredNodeIDs.includes(node._id.$oid)
              : true,
            goToDoc: goToDoc, // Pass goToDoc function to each node
          },
        }));

        setNodes(formattedNodes);

        const formattedEdges = processConnections(data);
        setEdges(formattedEdges);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching nodes:', error);
        setLoading(false);
      }
    };

    fetchSnippets();
  }, [caseID, setNodes, setEdges, filteredNodeIDs, goToDoc]);

  const processConnections = nodesData => {
    const edgeSet = new Set();
    const formattedEdges = [];

    nodesData.forEach(node => {
      const sourceId = node._id.$oid;
      if (node?.connections?.length) {
        node.connections.forEach(targetId => {
          const target = targetId.$oid;
          const edgeId = [sourceId, target].sort().join('-');
          if (!edgeSet.has(edgeId)) {
            edgeSet.add(edgeId);
            formattedEdges.push({
              id: edgeId,
              source: sourceId,
              target: target,
              type: 'smoothstep',
              style: { strokeWidth: 3, stroke: '#00B870' },
            });
          }
        });
      }
    });
    return formattedEdges;
  };

  const saveAddedEdge = async (sourceId, targetId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_edge`,
        {
          source_id: sourceId,
          target_id: targetId,
        }
      );
      console.log('Edge added successfully:', response.data);
    } catch (error) {
      console.error('Error adding edge:', error);
      // You might want to handle the error, e.g., by showing a notification to the user
    }
  };

  const saveRemovedEdge = async (sourceId, targetId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/remove_edge`,
        {
          source_id: sourceId,
          target_id: targetId,
        }
      );
      console.log('Edge removed successfully:', response.data);
    } catch (error) {
      console.error('Error removing edge:', error);
      // You might want to handle the error, e.g., by showing a notification to the user
    }
  };

  const updateNodePosition = async (nodeId, newPosition) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/update_node_position`,
        {
          node_id: nodeId,
          coordinates: {
            x: newPosition.x,
            y: newPosition.y,
          },
        }
      );
    } catch (error) {
      console.error('Error updating node position:', error);
      // You might want to handle the error, e.g., by showing a notification to the user
    }
  };

  const deleteNode = async nodeId => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/delete_node`,
        {
          node_id: nodeId,
        }
      );
    } catch (error) {
      console.error('Error updating node position:', error);
      // You might want to handle the error, e.g., by showing a notification to the user
    }
  };

  const onConnect = useCallback(
    params => {
      setEdges(eds => addEdge(params, eds));
      saveAddedEdge(params.source, params.target);
    },
    [setEdges]
  );

  const handleEdgesChange = useCallback(
    changes => {
      changes.forEach(change => {
        if (change.type === 'remove') {
          const deletedEdge = edges.find(edge => edge.id === change.id);
          if (deletedEdge) {
            saveRemovedEdge(deletedEdge.source, deletedEdge.target);
          }
        }
      });
      onEdgesChange(changes);
    },
    [edges, onEdgesChange]
  );

  const handleNodesChange = useCallback(
    changes => {
      changes.forEach(change => {
        if (change.type === 'position' && change.dragging === false) {
          // Node drag has ended, update the position in the backend
          const updatedNode = nodes.find(node => node.id === change.id);
          if (updatedNode) {
            updateNodePosition(change.id, updatedNode.position);
          }
        } else if (change.type === 'remove') {
          deleteNode(change.id);
        }
      });
      onNodesChange(changes);
    },
    [nodes, onNodesChange]
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    async event => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');

      const position = reactFlowInstance.project({
        x: event.clientX - 150,
        y: event.clientY - 150,
      });

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_node`,
          {
            case_id: caseID,
            node_type: type,
            coordinates: position,
          }
        );

        const nodeId = response.data.node_id;

        const newNode = {
          id: nodeId,
          type,
          position,
          data: {
            _id: { $oid: nodeId },
            caseID: caseID, // Add caseID to new node data
            isFiltered: true,
            showOptions: type === 'snippet',
            onFillManually: () => handleFillManually(nodeId),
            onFillWithInstructions: () => handleFillWithInstructions(nodeId),
          },
        };

        setNodes(nds => {
          return nds.concat(newNode);
        });
      } catch (error) {
        console.error('Error adding new node:', error);
      }
    },
    [reactFlowInstance, setNodes, caseID]
  );

  const handleFillManually = useCallback(
    nodeId => {
      setNodes(nds =>
        nds.map(node =>
          node.id === nodeId
            ? {
                ...node,
                data: { ...node.data, showOptions: false, fillType: 'manual' },
              }
            : node
        )
      );
      // Add logic here to open a form or modal for manual input
    },
    [setNodes]
  );

  const handleFillWithInstructions = useCallback(
    nodeId => {
      setNodes(nds =>
        nds.map(node =>
          node.id === nodeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  showOptions: false,
                  fillType: 'instructions',
                },
              }
            : node
        )
      );
    },
    [setNodes]
  );
  if (loading) {
    return <div>Loading...</div>;
  }

  const isValidConnection = connection => {
    // Custom logic to determine if the connection is valid
    return true; // Allow all connections
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {filteredNodeIDs && <FilterBanner onClear={clearNodeFilter} />}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        connectionMode="loose"
        isValidConnection={isValidConnection}
        proOptions={{ hideAttribution: true }}
        onDragOver={onDragOver}
        onDrop={onDrop}
        fitView
        defaultEdgeOptions={{
          type: 'smoothstep',
          style: {
            strokeWidth: 3,
            stroke: '#00B870',
          },
        }}
      >
        <Controls />
        <MiniMap />
        <Background color="#ccc" size={2} gap={30} />
      </ReactFlow>
      <div
        className="node-options"
        style={{ position: 'absolute', bottom: '30px', left: '60px' }}
      >
        <Button
          variant={'default_muted_2'}
          onDragStart={event =>
            event.dataTransfer.setData('application/reactflow', 'snippet')
          }
          draggable
          mr={2}
        >
          Add Snippet
        </Button>
        <Button
          bg={'#fcfaee'}
          variant={'default_muted_2'}
          onDragStart={event =>
            event.dataTransfer.setData('application/reactflow', 'instruction')
          }
          draggable
        >
          Add Instruction
        </Button>
      </div>
    </div>
  );
};


const SnippetsTab = ({ caseID, filteredNodeIDs, clearNodeFilter, goToDoc }) => {
  return (
    <div
      className="snippets-page"
      style={{ height: 'calc(100vh - 180px)', width: '100%' }}
    >
      <ReactFlowProvider>
             <Flow
              caseID={caseID}
              filteredNodeIDs={filteredNodeIDs}
              clearNodeFilter={clearNodeFilter}
              goToDoc={goToDoc}
            />
      </ReactFlowProvider>
    </div>
  );
};

export default SnippetsTab;
