import React from "react";
import { Box, Text } from '@chakra-ui/react'

const RenderKeyIssues = ({ data }) => {
    return (
        <>{data.map((item, index) => <Box key={index}>
            <Text fontWeight="bold" fontSize={'1.2rem'} color={'#004732'} data-section-title={item.title}>{item.title}</Text>
            <Text fontWeight="bold">Claimant's Statement:</Text>
            <Text mb={2} data-section-type="claimant" data-section-document-source="Claimant's Pleading">{item.claimant_statement}</Text>
            <Text fontWeight="bold">Respondent's Statement:</Text>
            <Text mb={2} data-section-type="respondent" data-section-document-source="Respondent's Pleading">{item.respondent_statement}</Text>
            <Text fontWeight="bold">Explanation:</Text>
            <Text mb={2} data-section-type="explanation">{item.explanation}</Text>
            {index < data.length - 1 && <Box as="hr" my={4} />}
        </Box>)}</>
    )
}

export default RenderKeyIssues