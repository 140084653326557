import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Text, Box, Flex, Button, HStack, VStack } from '@chakra-ui/react';
import { AuthContext } from './../../helpers/AuthContext';
import moment from 'moment';
import { motion } from 'framer-motion';

import './index.scss';

const AnalysisHistory = ({ globalState, setGlobalState }) => {
  const navigate = useNavigate();
  const { isLoading } = useContext(AuthContext);
  const [pastAudits, setPastAudits] = useState([]);
  const fetchedRef = useRef(false);

  useEffect(() => {
    if (!isLoading && !fetchedRef.current) {
      fetchedRef.current = true;
      fetchAnalysisHistory();
    }
  }, [isLoading]);

  const fetchAnalysisHistory = async () => {
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/analysis_history`;
    const response = await axios.get(url);
    setPastAudits(response.data);
  };

  const convertToRelativeTime = timestamp => {
    return moment(timestamp * 1000).fromNow();
  };

  const handleNavigateToAnalysis = async caseID => {
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/analysis`;
      const response = await axios.get(`${url}?case_id=${caseID}`);
      // Process the documents
      const processedDocuments = response.data.document_info.map(doc => {
        // Convert base64 to ArrayBuffer
        const binaryString = window.atob(doc.doc_binary);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const arrayBuffer = bytes.buffer;
  
        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  
        // Create a File object from the Blob
        const file = new File([blob], `${doc.tags.join(' / ')}`, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  
        return {
          ...doc,
          file: file,
          blob: blob,
        };
      });
  
      // Set the global state
      setGlobalState(prevState => ({
        ...prevState,
        case_id: response.data.case_id,
        case_title: response.data.case_title,
        documents: processedDocuments,
        available_functions: response.data.available_functions,
        suggested_search_terms: response.data.suggested_search_terms
      }));
  
      // Navigate to the analysis page
      navigate(`/dispute_analysis_new/${caseID}`);
    } catch (error) {
      console.error('Error fetching analysis:', error);
      // Handle error (e.g., show error message to user)
    }
  };
  

  const RenderAuditCard = ({ data }) => {
    return (
      <Box className="card" my={4} py={4} px={8}>
        <Flex justify={'space-between'} align={'center'}>
          <Box>
            <HStack>
              {data.status === 'ongoing' && (
                <motion.div className="ongoing-indicator">
                  {[0, 1, 2].map(index => (
                    <motion.div
                      key={index}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderRadius: '50%',
                        background: 'green',
                        transform: 'translate(-50%, -50%)',
                      }}
                      animate={{
                        width: ['12px', '20px', '26px'],
                        height: ['12px', '20px', '26px'],
                        opacity: [0.7, 0.4, 0.6],
                      }}
                      transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: [0, 1, 2],
                        repeat: Infinity,
                        delay: index * 1,
                      }}
                    />
                  ))}
                </motion.div>
              )}
              <VStack justify={'start'} align={'self-start'}>
                {data.status === 'ongoing' && (
                  <Text fontWeight={600} color={'black'} fontSize={'1.1rem'}>
                    Analysis Ongoing
                  </Text>
                )}
                {data.status === 'completed' && (
                  <Text fontWeight={600} color={'black'} fontSize={'1.1rem'}>
                    {data.case_title}
                  </Text>
                )}
                <Text>
                  {data.created_at && convertToRelativeTime(data.created_at)}
                </Text>
              </VStack>
            </HStack>
          </Box>
          <Flex align={'center'}>
            <Button onClick={() => handleNavigateToAnalysis(data.case_id)}>
              View results
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      <Box className="analysis-history-wrapper" maxW={'860px'} mt={16}>
        {pastAudits.map(data => (
          <RenderAuditCard key={data.case_id} data={data} />
        ))}
      </Box>
    </Flex>
  );
};

export default AnalysisHistory;
