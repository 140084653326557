import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { Box, Divider, Collapse, useDisclosure } from '@chakra-ui/react';

const EventNode = forwardRef(({ data, goToReference }, ref) => {
  const [tags, setTags] = useState([]);
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const { references } = data;
    const refsElements = references.map((ref, index) => (
      <div
        key={`ref-${index}`}
        className="reference-text"
        onClick={() => goToReference(ref)}
      >
        {ref.tags.join(' / ')}
        <sup>{` [${index + 1}]`}</sup>
        {index < references.length - 1 ? ', ' : ''}
      </div>
    ));
    setTags(refsElements);
  }, [data, goToReference]);

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getHeight: () => innerRef.current?.clientHeight || 0,
  }));

  return (
    <div ref={innerRef} className="event-node">
      <div className="date-box" style={{ maxWidth: '150px' }}>
        {data.date}
      </div>
      <div className="detail-box">
        <p>{data.description}</p>
        <Divider my={2} />
        <div className="references-toggle" onClick={onToggle}>
          {isOpen ? 'Collapse References' : 'View references'}
        </div>
        <Collapse in={isOpen} animateOpacity>
          <Box mt="4" rounded="md" shadow="md">
            {tags}
          </Box>
        </Collapse>
      </div>
    </div>
  );
});

export default EventNode;
