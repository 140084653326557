import React, { useState, useEffect, useRef } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Flex,
} from '@chakra-ui/react';

import AnalysisTab from './AnalysisTab';
import SnippetsTab from './SnippetsTab';
import DeliverablesTab from './DeliverablesTab';
import DocxPreview from './DocxPreview';

import OmniSearch from '../../components/OmniSearch';
import { SearchIcon } from '@chakra-ui/icons';

import { motion } from 'framer-motion';
import {
  removeAllSpaces,
  highlightAndScrollToText,
} from '../../helpers/DocHighlightHelper';

const DisputeViewTabs = ({ caseID, documents, suggestedSearchTerms }) => {
  const [filteredNodeIDs, setFilteredNodeIDs] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const [allTags, setAllTags] = useState([]); // For Omnisearch auto-complete
  const [isDocPreviewExpanded, setIsDocPreviewExpanded] = useState(false);
  const [previewSelectedDocID, setPreviewSelectedDocID] = useState(null);
  const [docPreviewClass, setDocPreviewClass] = useState(''); // Used to account for varied heights across different tabs
  const docPreviewRef = useRef(null);

  useEffect(() => {
    const previewContainerClasses = ['analysis_tab', 'snippets_tab', 'deliverables_tab'];
    setDocPreviewClass(previewContainerClasses[tabIndex])
  }, [tabIndex])

  const goToDoc = (doc_id, doc_text) => {
    if (!isDocPreviewExpanded) setIsDocPreviewExpanded(true);
    setPreviewSelectedDocID(doc_id);
    setTimeout(() => {
      const processedText = removeAllSpaces(doc_text);
      highlightAndScrollToText(docPreviewRef.current, processedText);
    }, 100);
  };

  useEffect(() => {
    const tagsList = documents?.flatMap(doc => doc.tags) || [];
    setAllTags(tagsList);
  }, [documents]);

  const handleGoToNodes = nodeIds => {
    setFilteredNodeIDs(nodeIds);
    setTabIndex(1);
  };

  const clearNodeFilter = () => {
    setFilteredNodeIDs(null);
  };

  const toggleCommandBar = () => {
    setIsCommandBarOpen(!isCommandBarOpen);
  };

  return (
    <Tabs
      variant={'default_pilled'}
      isLazy
      index={tabIndex}
      onChange={index => setTabIndex(index)}
    >
      <TabList>
        <Tab>Analysis</Tab>
        <Tab>Snippets</Tab>
        <Tab>Deliverables</Tab>
      </TabList>

      <Flex>
        <motion.div
          initial={{ width: '60%' }}
          animate={{ width: isDocPreviewExpanded ? '60%' : '95%' }}
          style={{
            display: 'flex',
            width: isDocPreviewExpanded ? '60%' : '95%',
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
            delay: isDocPreviewExpanded ? 0 : 0.4,
          }}
        >
          <TabPanels>
            <TabPanel>
              <AnalysisTab
                documents={documents}
                caseID={caseID}
                goToDoc={goToDoc}
              />
            </TabPanel>
            <TabPanel>
              <SnippetsTab
                caseID={caseID}
                filteredNodeIDs={filteredNodeIDs}
                clearNodeFilter={clearNodeFilter}
                documents={documents}
                goToDoc={goToDoc}
              />
            </TabPanel>
            <TabPanel>
              <DeliverablesTab caseID={caseID} goToNodes={handleGoToNodes} />
            </TabPanel>
          </TabPanels>
        </motion.div>

        <DocxPreview
          currentTab={docPreviewClass}
          previewSelectedDocID={previewSelectedDocID}
          setPreviewSelectedDocID={setPreviewSelectedDocID}
          docPreviewRef={docPreviewRef}
          documents={documents}
          isExpanded={isDocPreviewExpanded}
          onToggle={() => setIsDocPreviewExpanded(!isDocPreviewExpanded)}
        />
      </Flex>

      <Button
        px={8}
        variant={'secondary'}
        onClick={toggleCommandBar}
        leftIcon={<SearchIcon mr={0} />}
        style={{
          position: 'absolute',
          right: '20px',
          top: '0',
        }}
      >
        Search
      </Button>

      <OmniSearch
        caseID={caseID}
        documents={documents}
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        allTags={allTags}
        suggestedSearchTerms={suggestedSearchTerms}
      />
    </Tabs>
  );
};

export default DisputeViewTabs;
