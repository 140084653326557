import { background, border, color, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Input: {
      variants: {
        default: {
          field: {
            padding: '26px 18px', // Change this to your desired padding
            bg: '#E8E8E8', // background
            color: 'black', // text color
            fontWeight: '500',
            border: '2px solid transparent',
            borderColor: 'transparent',
            borderWidth: '2px',
            outline: 'none',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
            _placeholder: {
              color: '#686868',
            },
            _loading: {
              background: "#000"
            },
            _hover: {
              borderColor: 'black',
              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0)'
            },
            _focus: {
              outline: 'none',
              border: '2px solid black',
              bg: '#F3F3F3',
              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0)'
            },
          },
          element: {
            padding: '20px'
          }
        },
        omnisearch: {
          field: {
            padding: '26px 18px', // Change this to your desired padding
            bg: '#fff', // background
            color: 'black', // text color
            fontWeight: '500',
            border: '2px solid #00B870',
            borderWidth: '2px',
            outline: 'none',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
            _placeholder: {
              color: '#686868',
            },
            _loading: {
              background: "#000"
            },
            _hover: {
              borderColor: '#00B870',
              boxShadow: 'none'
            },
            _focus: {
              outline: 'none',
              border: '2px solid #00B870',
              bg: '#F3F3F3',
              boxShadow: 'none'
            },
          },
          element: {
            padding: '20px'
          }
        },
        default_highlighted: {
          field: {
            padding: '26px 18px', // Change this to your desired padding
            bg: '#E8E8E8', // background
            color: 'black', // text color
            fontWeight: '500',
            border: '2px solid black',
            borderColor: 'black',
            borderWidth: '2px',
            outline: 'none',
            _placeholder: {
              color: '#686868',
            },
            _hover: {
              borderColor: 'black',
            },
            _focus: {
              outline: 'none',
              border: '2px solid black',
              bg: '#F3F3F3'
            },
            _invalid: {
              borderColor: 'red',
              _focus: {
                borderColor: 'red',
              }
            },
          },
          element: {
            padding: '20px'
          }
        },
        default_file: {
          field: {
            padding: '26px 18px 26px 140px', // Change this to your desired padding
            bg: '#E8E8E8', // background
            color: 'black', // text color
            fontWeight: '500',
            border: '2px solid transparent',
            borderColor: 'transparent',
            borderWidth: '2px',
            outline: 'none',
            _placeholder: {
              color: '#686868',
            },
            _hover: {
              borderColor: 'black',
            },
            _focus: {
              outline: 'none',
              border: '2px solid black',
              bg: '#F3F3F3'
            },
          },
          element: {
            padding: '20px'
          }
        }
      }
    },
    Textarea: {
      variants: {
        default: {
          padding: '8px 18px',
          bg: '#E8E8E8', // background
          color: 'black', // text color
          fontWeight: '500',
          border: '2px solid transparent',
          borderColor: 'transparent',
          borderWidth: '2px',
          _focus: {
            outline: 'none',
            border: '2px solid black',
            bg: '#F3F3F3'
          }
        }
      }
    },
    Select: {
      variants: {
        default: {
          field: {
            height: '52px',
            padding: '0 18px',
            bg: '#E8E8E8', // background
            color: 'black', // text color
            fontWeight: '500',
            border: '2px solid transparent',
            borderColor: 'transparent',
            borderWidth: '2px',
            _hover: {
              borderColor: 'black',
            }
          }
        },
      }
    },
    Button: {
      variants: {
        default: {
          bg: 'black',
          color: '#fff',
          _hover: {
            bg: '#1C1C1C',
            boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: '#303030',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }
        },
        secondary: {
          bg: '#00B870',
          color: '#fff',
          _hover: {
            bg: '#00995D',
            boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: '#00995D',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }
        },
        outline: {
          bg: 'transparent',
          color: '#00995D',
          border: '2px solid #00995D',
          borderWidth: '2px',
          borderColor: '#00995D',
          _hover: {
            bg: '#00995D',
            boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: '#303030',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }
        },
        outline_muted: {
          bg: 'rgba(0,0,0,0.05)',
          color: '#6A6A6A',
          border: '1px solid #cfcfcf',
          borderWidth: '1px',
          borderColor: '#cfcfcf',
          _hover: {
            bg: 'rgba(0,0,0,0.08)',
            boxShadow: '2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: 'rgba(0,0,0,0.08)',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }
        },
        default_muted: {
          bg: '#dfdfdf',
          color: '#000',
          _hover: {
            bg: '#F7F7F7',
            boxShadow: 'inset 2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: '#303030',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }
        },
        default_muted_2: {
          bg: '#fff',
          color: '#515151',
          fontSize: '14px',
          border: '1px solid #cfcfcf',
          _hover: {
            bg: '#F7F7F7',
            boxShadow: 'inset 2px 2px 2px 0 rgba(0,0,0,0.08)'
          },
          _focus: {
            bg: '#F7F7F7',
            boxShadow: 'none'
          },
          _disabled: {
            bg: "#707070",
            cursor: "not-allowed",
            _hover: {
              bg: "#707070 !important"
            },
            _focus: {
              bg: "#707070"
            }
          }

        },
        ghost: {
          background: 'transparent',
          color: '#515151'
        }
      }
    },
    Tag: {
      variants: {
        default: {
          container: {
            bg: '#D9D9D9'
          }
        }
      }
    },
    Kbd: {
      variants: {
        dark: {
          background: 'black',
        },
        default: {
          background: 'white',
          fontSize: '0.9rem'
        }
      }
    },
    Badge: {
      variants: {
        default: {
          background: '#E8E8E8',
          color: '#000000'
        },
        default_muted: {
          background: '#F4F4F4',
          color: '#7B7B7B'
        }
      }
    },
    Checkbox: {
      variants: {
        default: {
          control: {
            padding: 2,
            borderColor: '#E6E6E6',
            _checked: {
              bg: 'black',
              borderColor: 'black'
            },
            // _hover: {
            //   bg: '#303030',
            //   borderColor: '#303030'
            // },
            _active: {
              bg: '#303030',
              borderColor: '#303030'
            }
          },
          label: {
            fontWeight: 500,
            fontSize: '1rem'
          }
        }
      }
    },
    Accordion: {
      variants: {
        default: {
          root: {
            border: 'none',
            borderWidth: 0,
            borderColor: 'transparent',
          },
          container: {
            borderRadius: '4px',
            overflow: 'hidden', // This ensures the border-radius is applied to the content
            _expanded: {
              borderColor: '#efefef',
              borderWidth: '2px',
              borderStyle: 'solid',
            },
          },
          button: {
            background: '#f1f1f1',
            padding: '14px 28px',
            borderRadius: '4px',
            position: 'sticky',
            top: 0,
            zIndex: 99,
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#4a4a4a',
            _expanded: {
              borderColor: '#efefef',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderBottom: 'none',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              color: '#00B870'
            }
          },
          panel: {
            // bg: 'blue.50', // This ensures the content area also has the blue background
            padding: '14px 28px',
            borderColor: '#efefef',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderTop: 'none'
          }
        }
      }
    },
    Tabs: {
      variants: {
        default: {
          tablist: {
            borderBottom: '4px solid #D9D9D9',
            fontWeight: 500
          },
          tab: {
            fontWeight: 500,
            paddingBottom: '12px',
            paddingLeft: '28px',
            paddingRight: '28px',
            _selected: {
              borderBottom: '4px solid #000',
              marginBottom: '-4px'
            },
          }
        },
        default_pilled: {
          tab: {
            borderRadius: "8px",
            fontWeight: "semibold",
            color: "gray.600",
            width: '100%',
            fontSize: '1em',
            p: '8px 12px',
            _selected: {
              color: "black",
              bg: "white",
              boxShadow: "md",
            },
          },
          tablist: {
            bg: "#F7F5F0",
            borderRadius: "8px",
            p: 1,
            maxWidth: '430px'
          },
          tabpanel: {
            // p: 0
            paddingLeft: '0px',
            paddingRight: '0px'
          }   
        }
      }
    }
  },
});

export default theme;