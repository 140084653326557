import axios from "axios";

const showReferences = async (selectedText, action, caseID, isMock = false, setIsTextActionLoading, setSelectedTextAction, goToDoc, setSelectedText, selectionMetadata) => {
    setSelectedTextAction(action);
    if (!selectedText.trim()) return;
    try {
      setIsTextActionLoading(true);
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/text_action`;
      const response = await axios.post(url, {
        text: selectedText,
        action: action,
        case_id: caseID,
        mock: isMock,
        selectionMetadata: selectionMetadata,
      });

      if (response.data && response.data.length > 0) {
        const result = response.data[0];
        let resultText = result.text;
        const referenceMap = new Map();

        result.references.forEach(reference => {
          const { id, doc_id, text } = reference;
          referenceMap.set(id, { doc_id, text });

          const refRegex = new RegExp(`<ref id='${id}'>(.*?)</ref>`, 'g');
          resultText = resultText.replace(refRegex, (match, p1) => {
            return `${p1}<div class="reference-id" data-reference-id="${id}">${id}</div>`;
          });
        });

        const range = window.getSelection().getRangeAt(0);
        const newNode = document.createElement('span');
        newNode.innerHTML = resultText;
        newNode.className = 'text-replaced-highlight';
        newNode.referenceMap = referenceMap; // Attach the map to the node for later use

        // Replace the selected text with the new node
        range.deleteContents();
        range.insertNode(newNode);

        // Add event listeners after inserting the node into the DOM
        const referenceElements = newNode.querySelectorAll('.reference-id');
        referenceElements.forEach(element => {
          element.addEventListener('click', event => {
            event.preventDefault();
            event.stopPropagation();

            const referenceId = element.getAttribute('data-reference-id');
            const reference = newNode.referenceMap.get(referenceId);

            if (reference) {
              const { doc_id, text } = reference;
              goToDoc(doc_id, text);
            }
          });
        });

        // Collapse the selection to the end of the new node
        range.setStartAfter(newNode);
        range.setEndAfter(newNode);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
      setSelectedText('');
      setIsTextActionLoading(false);
    } catch (error) {
      setIsTextActionLoading(false);
      // Handle error as needed
    }
};

export { showReferences };