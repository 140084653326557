import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Button,
  Text,
  Heading,
  Card,
  CardBody,
  CardFooter,
  useToast,
  Input,
  Container,
  Spinner
} from '@chakra-ui/react';
import TypeSelect from '../../components/TypeSelect';

import ExecutiveSummaryIcon from '../../images/executive_summary.svg';
import NotesIcon from '../../images/notes.svg';
import RejoinderIcon from '../../images/rejoinder.svg';
import { ReactComponent as RetryIcon } from '../../images/retry.svg';
import { ReactComponent as NodesIcon } from '../../images/nodes.svg';
import { ReactComponent as AttachIcon } from '../../images/attach.svg';
import { ReactComponent as WarningIcon } from '../../images/warning.svg';
import { ReactComponent as ArrowBackIcon } from '../../images/arrow-back.svg';
import { deleteCookie, getCookie, setCookie } from '../../helpers/cookies';

const DeliverablesTab = ({ caseID, goToNodes }) => {
  const [step, setStep] = useState(1);
  const [documentType, setDocumentType] = useState('');
  const [generationGroups, setGenerationGroups] = useState([]);
  const [isGenerating, setIsGenerating] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const fileInputRefs = useRef({});

  useEffect(() => {
    const selectedDocumentType = getCookie('selectedDocumentType');
    if (selectedDocumentType) setStep(2);
  }, []);

  useEffect(() => {
    if (step === 2) {
      setCookie('selectedDocumentType', documentType, 20);
      fetchGenerationGroups();
    }
  }, [step, caseID]);

  useEffect(() => {
    if (generationGroups.length > 0 && !selectedGroup) {
      setSelectedGroup(generationGroups[0]);
    }
  }, [generationGroups]);

  const fetchGenerationGroups = async () => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_generation_groups`;
      const response = await axios.get(url, { params: { case_id: caseID } });
      setGenerationGroups(response.data.groups);
    } catch (error) {
      console.error('Error fetching generation groups:', error);
      toast({
        title: 'Error fetching groups',
        description: 'Unable to fetch generation groups. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerate = async groupId => {
    setIsGenerating(prev => ({ ...prev, [groupId]: true }));
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/generate_from_group`;
      const formData = new FormData();
      formData.append('group_id', groupId);
      formData.append('case_id', caseID);

      if (uploadedFiles[groupId]) {
        uploadedFiles[groupId].forEach((file, index) => {
          formData.append(`additional_document_${index}`, file);
        });
      }

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast({
        title: 'Generation Successful',
        description: 'Content has been generated for the group.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchGenerationGroups();
      setSelectedGroup(response.data);
    } catch (error) {
      console.error('Error generating content:', error);
      toast({
        title: 'Generation Failed',
        description: 'Unable to generate content. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const handleGoToNodes = ({ nodes }) => {
    const nodesToShow = nodes.map(node => node.id);
    goToNodes(nodesToShow);
  };

  const handleSelectGroup = group => {
    setSelectedGroup(group);
  };

  const handleFileUpload = (groupId, event) => {
    const files = Array.from(event.target.files).filter(file =>
      file.name.endsWith('.docx')
    );
    setUploadedFiles(prev => ({
      ...prev,
      [groupId]: files,
    }));
  };

  const handleGoBack = () => {
    deleteCookie('selectedDocumentType');
    setStep(1);
  }

  const triggerFileInput = groupId => {
    fileInputRefs.current[groupId].click();
  };

  const renderStep1 = () => (
    <Box>
      <Container mt={12}>
        <VStack spacing={4} align="stretch">
          <TypeSelect
            title={'Select Deliverable Type'}
            options={[
              {
                title: 'Executive Summary',
                type: 'executive_summary',
                description:
                  'Construct documentation capturing the high level overview of the dispute',
                icon: ExecutiveSummaryIcon,
              },
              {
                title: 'Notes',
                type: 'notes',
                description:
                  'Capture precise information to share with key stakeholders',
                icon: NotesIcon,
              },
              {
                title: 'Points for Rejoinders',
                type: 'rejoinders',
                description:
                  'Gather key points from existing and additional documents to convert them into subsequent pleadings',
                icon: RejoinderIcon,
              },
            ]}
            onChange={doc_type => setDocumentType(doc_type)}
          />
          <Button
            variant={'default'}
            onClick={() => setStep(2)}
            isDisabled={!documentType}
          >
            Next
          </Button>
        </VStack>
      </Container>
    </Box>
  );

  const renderStep2 = () => (
    <Flex h="100%" w="100%">
    {isLoading ? (
      <Flex justify="center" align="center" w="100%">
        <Spinner size="xl" />
        <Text ml={4}>Loading generation groups...</Text>
      </Flex>
    ) : (
      <><Box w="50%" overflowY="auto" pr={4}>
        <VStack spacing={4} align="stretch">
          {generationGroups.map(group => (
            <Card
              key={group.group_id}
              cursor="pointer"
              onClick={() => handleSelectGroup(group)}
              borderWidth={
                selectedGroup && selectedGroup.group_id === group.group_id
                  ? '2px'
                  : '1px'
              }
              borderColor={
                selectedGroup && selectedGroup.group_id === group.group_id
                  ? 'blue.500'
                  : 'gray.200'
              }
            >
              <CardBody>
                <Heading size="md" mb={2}>
                  {group.title}
                </Heading>
                <Text>{group.nodes.length} Nodes</Text>
                <Text color={group.has_changed ? 'red.500' : 'green.500'}>
                  {group.has_changed && (
                    <Box display={'flex'} flexDirection={'row'} mt={4} alignItems={'center'}>
                      <WarningIcon fill='#ff0000' />
                      <Text mt={1} ml={2} color={'#ff0000'}>Nodes have been changed since last generation</Text>
                    </Box>
                  )}
                </Text>
                {uploadedFiles[group.group_id] && (
                  <Text>
                    Attached documents: {uploadedFiles[group.group_id].length}
                  </Text>
                )}
              </CardBody>
              <CardFooter>
                <HStack>
                  <Button
                    variant={'ghost'}
                    leftIcon={<RetryIcon fill="#929292" />}
                    onClick={e => {
                      e.stopPropagation();
                      handleGenerate(group.group_id);
                    }}
                    isLoading={isGenerating[group.group_id]}
                    loadingText="Generating"
                  >
                    {group.has_changed ? 'Generate' : 'Retry'}
                  </Button>
                  <Button
                    variant={'ghost'}
                    leftIcon={<NodesIcon fill="#929292" />}
                    onClick={e => {
                      e.stopPropagation();
                      handleGoToNodes(group);
                    }}
                  >
                    Go To Nodes
                  </Button>
                  <Button
                    variant={'ghost'}
                    leftIcon={<AttachIcon fill="#929292" />}
                    onClick={e => {
                      e.stopPropagation();
                      triggerFileInput(group.group_id);
                    }}
                  >
                    Attach Documents
                  </Button>
                  <Input
                    type="file"
                    accept=".docx"
                    multiple
                    onChange={e => handleFileUpload(group.group_id, e)}
                    ref={el => (fileInputRefs.current[group.group_id] = el)}
                    display="none"
                  />
                </HStack>
              </CardFooter>
            </Card>
          ))}
        </VStack>
      </Box>
      <Box
        w="50%"
        overflowY="auto"
        pl={4}
        borderLeft="1px"
        borderColor="gray.200"
      >
        {selectedGroup ? (
          <Box>
            <Heading size="lg" mb={4}>
              {selectedGroup.title}
            </Heading>
            {selectedGroup.generation_output ? (
              <Box
                dangerouslySetInnerHTML={{
                  __html: selectedGroup.generation_output,
                }}
              />
            ) : (
              <Text>Generation has not happened yet for this group.</Text>
            )}
          </Box>
        ) : (
          <Text>Select a group to view its details and generation output.</Text>
        )}
      </Box></>
    )}
    </Flex>
  );

  const renderStep3 = () => (
    <Box>
      <Heading size="md" mb={4}>
        Step 3: Finalization
      </Heading>
      <Button onClick={() => console.log('Finalized')}>Finalize</Button>
    </Box>
  );

  return (
    <>
      {step === 2 && <Flex mb={4} justify={'start'}>
        <Button onClick={handleGoBack} leftIcon={<ArrowBackIcon fill='#929292'/>} variant={'ghost'}>Go back</Button>
      </Flex>}
    <Box>
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
    </Box>
    </>
  );
};

export default DeliverablesTab;
