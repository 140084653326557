import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { renderAsync } from 'docx-preview';

import { highlightAndScrollToText, removeAllSpaces } from './../../helpers/DocHighlightHelper'

const DocPreview = ({ docID, documents, selectedText }) => {
  const docPreviewRef = useRef(null);

  useEffect(() => {
    let currentDoc = documents.find(doc => doc.id === docID);
    if (docPreviewRef.current) {
      renderAsync(currentDoc.file, docPreviewRef.current)
        .then(() => {
          goToDoc(selectedText);
        })
        .catch(err => console.error(err));
    }
  }, [docID, documents, selectedText]);


  const goToDoc = (doc_text) => {
    setTimeout(() => {
      const processedText = removeAllSpaces(doc_text);
      highlightAndScrollToText(docPreviewRef.current, processedText);
    }, 10);
  };


  return (
    <>
      <Box
        height={'calc(100vh - 124px)'}
        overflowY={'auto'}
        ref={docPreviewRef}
      ></Box>
    </>
  );
};

export default DocPreview;
