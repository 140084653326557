import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useReactFlow } from "reactflow";
import { useToast } from "@chakra-ui/react";
import axios from 'axios';


const useFillSnippet = () => {
  const toast = useToast();
  const { setNodes } = useReactFlow();

  const fillSnippet = useCallback(async (nodeId, fillType, caseID, content = null) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fill_snippet_with_instruction`,
        {
          case_id: caseID,
          instruction_text: content,
        }
      );

      const updatedSnippetData = response.data.complete_context;
      const updatedSnippetSource = response.data.doc_tags.join(" / ");
      const updatedSnippetRefernces = response.data.references_context

      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                snippet_body: updatedSnippetData,
                snippet_metadata: {
                  ...node.data.snippet_metadata,
                  documentSource: updatedSnippetSource,
                },
                references_context: {
                  ...updatedSnippetRefernces
                },
                showOptions: false,
                isEditing: true, // Set to true so we can edit the result
                fillType: null,
              },
            };
          }
          return node;
        })
      );

      // Return the response data
      return {
        complete_context: updatedSnippetData,
        doc_tags: response.data.doc_tags,
        references_context: {
          updatedSnippetRefernces
        }
      };
    } catch (error) {
      console.error('Error updating snippet:', error);
      toast({
        title: 'Error updating snippet',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      throw error; // Re-throw the error so we can catch it in the component
    }
  }, [setNodes, toast]);

  return fillSnippet;
};

  export default useFillSnippet