import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Tooltip,
  Input,
  Text,
  useColorModeValue,
  List,
  ListItem,
  Badge,
  Flex,
  Kbd,
  HStack,
  Skeleton,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as ArrowBackIcon } from '../../images/arrow-back.svg';

import DocPreview from './SearchDocPreview';
import axios from 'axios';
import './index.scss';

const OmniSearch = ({
  isOpen,
  onClose,
  caseID,
  documents,
  allTags,
  suggestedSearchTerms,
}) => {
  const inputRef = useRef(null);
  const selectedItemRef = useRef(null);

  const [hasShownDropdown, setHasShownDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedSearchIndex, setSelectedSearchIndex] = useState(0);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const [charactersTypedAfterDropdown, setCharactersTypedAfterDropdown] =
    useState(0); // Used for checking if the "no tags shown" state should be hidden
  const [selectedAutocompleteIndex, setSelectedAutocompleteIndex] =
    useState(-1);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen, inputRef]);

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedSearchIndex]);

  const performSearch = async query => {
    if (query.trim() === '') {
      setSearchResults([]);
      setIsExpanded(false);
      return;
    }

    setIsLoading(true);
    try {
      setIsExpanded(true);
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/omnisearch?case_id=${caseID}&query_text=${query}`;
      const response = await axios.get(url);
      setSearchResults(response.data.results);
      setSelectedSearchIndex(0);
      setShowAutocomplete(false);
    } catch (error) {
      console.error('Search error:', error);
      // Optionally, display an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === '') {
      setSearchResults([]);
      setIsExpanded(false);
      setShowAutocomplete(false);
      setHasShownDropdown(false);
      setCharactersTypedAfterDropdown(0);
      return;
    }
    if (hasShownDropdown) {
      setCharactersTypedAfterDropdown(prev => prev + 1);
    }

    const triggerWords = ['from', 'in', 'from the', 'as per'];
    const words = value.split(' ');

    let triggerWordIndex = -1;
    for (let i = 0; i < words.length; i++) {
      const potentialTrigger = words.slice(i, i + 2).join(' ');
      if (
        triggerWords.includes(words[i].toLowerCase()) ||
        triggerWords.includes(potentialTrigger.toLowerCase())
      ) {
        triggerWordIndex = i;
        break;
      }
    }

    if (triggerWordIndex !== -1) {
      const beforeTrigger = words.slice(0, triggerWordIndex + 1).join(' ');
      const afterTrigger = words.slice(triggerWordIndex + 1).join(' ');

      const filteredTags = allTags.filter(tag =>
        tag.toLowerCase().startsWith(afterTrigger.toLowerCase())
      );

      const formattedOptions = filteredTags.map(tag => ({
        fullText: `${beforeTrigger}`,
        matchedPart: tag,
      }));

      setShowAutocomplete(true);
      setAutocompleteOptions(formattedOptions);
      if (!hasShownDropdown && formattedOptions.length > 0) {
        setHasShownDropdown(true);
        setCharactersTypedAfterDropdown(0);
      }
    } else {
      setShowAutocomplete(false);
    }

    setSelectedAutocompleteIndex(-1);
  };

  const handleSuggestedSearchTermClick = term => {
    setSearchTerm(term);
    performSearch(term);
  };

  const handleAutocompleteSelect = fullText => {
    const words = searchTerm.split(' ');
    const triggerWords = ['from', 'in', 'from the', 'as per'];

    let triggerIndex = -1;
    for (let i = 0; i < words.length; i++) {
      const potentialTrigger = words.slice(i, i + 2).join(' ');
      if (
        triggerWords.includes(words[i].toLowerCase()) ||
        triggerWords.includes(potentialTrigger.toLowerCase())
      ) {
        triggerIndex = i;
        break;
      }
    }

    if (triggerIndex !== -1) {
      // Replace everything after the trigger word with the selected fullText
      const beforeTrigger = words.slice(0, triggerIndex + 1).join(' ');
      const newSearchTerm = `${beforeTrigger} ${fullText
        .split(beforeTrigger)[1]
        .trim()}`;
      setSearchTerm(newSearchTerm + ' ');
    } else {
      // If no trigger word found, just use the fullText as is
      setSearchTerm(fullText + ' ');
    }

    setShowAutocomplete(false);
    setSelectedAutocompleteIndex(-1);

    // Focus the input and move the cursor to the end
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        const newCursorPosition = inputRef.current.value.length;
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }, 0);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (showAutocomplete && selectedAutocompleteIndex !== -1) {
        const selectedText = autocompleteOptions[selectedAutocompleteIndex];
        const completeSelectedText =
          selectedText.fullText + ' ' + selectedText.matchedPart;
        handleAutocompleteSelect(completeSelectedText);
      } else {
        performSearch(searchTerm);
      }
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      if (document.activeElement === inputRef.current) {
        // If focus is on the input, let the input handle arrow keys
        if (showAutocomplete) {
          e.preventDefault();
          const direction = e.key === 'ArrowDown' ? 1 : -1;
          setSelectedAutocompleteIndex(
            prev =>
              (prev + direction + autocompleteOptions.length) %
              autocompleteOptions.length
          );
        }
      } else if (isExpanded && searchResults.length > 0) {
        // If focus is not on the input, handle search result navigation
        e.preventDefault();
        const direction = e.key === 'ArrowDown' ? 1 : -1;
        setSelectedSearchIndex(
          prev =>
            (prev + direction + searchResults.length) % searchResults.length
        );
      }
    } else if (e.key === 'Escape') {
      setShowAutocomplete(false);
      setSelectedAutocompleteIndex(-1);
    }
  };

  const SearchResultsSkeleton = () => (
    <List spacing={3}>
      {[...Array(5)].map((_, index) => (
        <ListItem key={index} p={2} borderWidth="1px" borderRadius="md" mb={2}>
          <SkeletonText noOfLines={4} spacing="2" />
          <Skeleton height="20px" width="100px" mt={2} />
        </ListItem>
      ))}
    </List>
  );

  const DocPreviewSkeleton = () => (
    <Box p={4}>
      <SkeletonText noOfLines={20} spacing="4" />
    </Box>
  );

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent
        className="omni-search-wrapper"
        as={motion.div}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        _focus={{ outline: 'none' }}
        layout
        bg={isExpanded ? bgColor : 'transparent'}
        borderRadius="lg"
        boxShadow="none"
        mt="10vh"
        mb="10vh"
        maxW={isExpanded ? '80vw' : '800px'}
        overflow="hidden"
        initial={{ height: '200px', width: '800px' }}
        animate={{
          height: isExpanded ? '80vh' : '380px',
          width: isExpanded ? '80vw' : '800px',
        }}
        transition={{ duration: 0.3 }}
      >
        <Box
          p={4}
          h="full"
          display="flex"
          flexDirection="column"
          backgroundColor={isExpanded ? '#f5f5f5' : '#F7F5F0'}
        >
          <motion.div
            initial={{ width: '100%' }}
            animate={{ width: isExpanded ? '800px' : '100%' }}
            transition={{ duration: 0.3 }}
          >
            <Box className="searchbar-only" mb={4} position="relative">
              <Input
                ref={inputRef}
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={e => {
                  if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    if (!showAutocomplete) {
                      e.preventDefault();
                      const direction = e.key === 'ArrowDown' ? 1 : -1;
                      setSelectedSearchIndex(
                        prev =>
                          (prev + direction + searchResults.length) %
                          searchResults.length
                      );
                    }
                  }
                }}
                placeholder="Search anything..."
                size="lg"
                pl={10}
                variant="omnisearch"
                _focus={{ boxShadow: 'outline' }}
                width="100%"
                maxH="60px"
              />
              <SearchIcon
                position="absolute"
                left={4}
                top="50%"
                transform="translateY(-50%)"
                color="#00B870"
              />
              {showAutocomplete && (
                <Box
                  position="absolute"
                  top="calc(100% + 12px)"
                  left={0}
                  right={0}
                  bg={bgColor}
                  borderWidth={1}
                  borderColor={borderColor}
                  borderRadius="md"
                  boxShadow="md"
                  maxH="220px"
                  maxW="auto"
                  overflowY="auto"
                  zIndex={999}
                >
                  {autocompleteOptions.length > 0
                    ? autocompleteOptions.map((option, index) => (
                        <Box
                          key={index}
                          py={4}
                          px={4}
                          cursor="pointer"
                          bg={
                            selectedAutocompleteIndex === index
                              ? 'gray.100'
                              : 'transparent'
                          }
                          _hover={{ bg: 'gray.100' }}
                          onClick={() =>
                            handleAutocompleteSelect(
                              `${option.fullText} ${option.matchedPart}`
                            )
                          }
                          whiteSpace={'nowrap'}
                        >
                          {option.fullText}{' '}
                          <strong>{option.matchedPart}</strong>
                        </Box>
                      ))
                    : hasShownDropdown &&
                      charactersTypedAfterDropdown <= 3 && (
                        <Box p={2} color="gray.500">
                          No tags found
                        </Box>
                      )}
                </Box>
              )}
            </Box>
          </motion.div>

          {isLoading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{ flex: 1, minHeight: 0, display: 'flex' }}
            >
              <Flex flex={1} minH={0}>
                <Box
                  w="30%"
                  overflowY="auto"
                  pr={2}
                  borderRight="1px"
                  borderColor={borderColor}
                >
                  <SearchResultsSkeleton />
                </Box>
                <Box
                  w="70%"
                  bg="#f5f5f5"
                  ml={4}
                  borderRadius="md"
                  overflowY="auto"
                >
                  <DocPreviewSkeleton />
                </Box>
              </Flex>
            </motion.div>
          ) : (
            <AnimatePresence>
              {isExpanded ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{ flex: 1, minHeight: 0, display: 'flex' }}
                >
                  <Flex flex={1} minH={0}>
                    <Box
                      onKeyDown={handleKeyDown}
                      tabIndex={0}
                      w="30%"
                      overflowY="auto"
                      pr={2}
                      borderRight="1px"
                      borderColor={borderColor}
                    >
                      {searchResults.length > 0 && (
                        <List className="search-results" spacing={3}>
                          {searchResults.map((result, index) => (
                            <ListItem
                              as={motion.li}
                              ref={
                                selectedSearchIndex === index
                                  ? selectedItemRef
                                  : null
                              }
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.2 }}
                              className={
                                selectedSearchIndex === index ? 'selected' : ''
                              }
                              onClick={() => setSelectedSearchIndex(index)}
                              key={index}
                              p={2}
                              borderWidth="1px"
                              borderRadius="md"
                              mb={2}
                            >
                              <Text noOfLines={4}>{result.text}</Text>
                              <Box mt={1}>
                                {result.tags.map((tag, tagIndex) => {
                                  const shouldShowTooltip = tag.length > 50;

                                  const badge = (
                                    <Badge
                                      key={tagIndex}
                                      mr={1}
                                      colorScheme="blue"
                                      whiteSpace="nowrap"
                                      maxWidth="200px"
                                      textOverflow="ellipsis"
                                      overflow="hidden"
                                    >
                                      {tag}
                                    </Badge>
                                  );

                                  return shouldShowTooltip ? (
                                    <Tooltip
                                      key={tagIndex}
                                      label={tag}
                                      aria-label={tag}
                                    >
                                      {badge}
                                    </Tooltip>
                                  ) : (
                                    badge
                                  );
                                })}
                              </Box>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Box>
                    <Box
                      w="70%"
                      bg="gray.200"
                      ml={4}
                      borderRadius="md"
                      overflowY="auto"
                    >
                      {searchResults.length > 0 && (
                        <DocPreview
                          docID={searchResults[selectedSearchIndex]?.doc_id}
                          selectedText={
                            searchResults[selectedSearchIndex]?.text
                          }
                          documents={documents}
                        />
                      )}
                    </Box>
                  </Flex>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <VStack align={'start'} ml={2} mb={4} spacing={0}>
                    <Text fontSize="lg" fontWeight="bold" mb={1}>
                      Suggested Search
                    </Text>
                    {suggestedSearchTerms.map((term, index) => (
                      <HStack
                        key={index}
                        className="suggested-search-term"
                        onClick={() => handleSuggestedSearchTermClick(term)}
                        w="100%"
                        justify="space-between"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: 'white' }}
                      >
                        <Text>{term}</Text>
                        <ArrowBackIcon
                          className="arrow-icon"
                          fill="#a6a6a6"
                          style={{ transform: 'rotate(180deg)' }}
                        />
                      </HStack>
                    ))}
                  </VStack>
                </motion.div>
              )}
            </AnimatePresence>
          )}

          {isExpanded && <Box
            className="keyboard-shortcuts-info"
            background={isExpanded ? '#F7F5F0' : '#f5f5f5'}
            py={2}
            px={2}
            borderRadius={'lg'}
          >
            <HStack align={'center'} justify={'center'}>
              {!isExpanded && (
                <>
                  <Text fontSize="sm" color="gray.500" mr={4}>
                    Press <Kbd variant={'default'}>↵</Kbd> to Search
                  </Text>
                  <Text fontSize="sm" color="gray.500" mr={4}>
                    |
                  </Text>
                </>
              )}
              <Text fontSize="sm" color="gray.500">
                Use <Kbd variant={'default'}>↑</Kbd>{' '}
                <Kbd variant={'default'}>↓</Kbd> to navigate
              </Text>
              <Text fontSize="sm" color="gray.500" mr={4}>
                |
              </Text>
              <Text fontSize="sm" color="gray.500">
                Press <Kbd variant={'default'}>ESC</Kbd> to close
              </Text>
            </HStack>
          </Box>}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default OmniSearch;
