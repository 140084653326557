import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ChakraProvider
} from '@chakra-ui/react';
import theme from './theme';
import axios from 'axios';
import Header from './components/Header'

import Home from "./modules/Home"
import Logout from "./modules/Logout"
import AnalysisHistory from "./modules/AnalysisHistory"
import QuestionAnswering from './modules/QuestionAnswering';
import MultiDocResults from './modules/MultiDocResults'
import DisputeAnalysisResults from './modules/DisputeAnalysisResults';

import { getCookie } from './helpers/cookies';
import { AuthContext } from './helpers/AuthContext';
import './styles/index.scss'
import { NoteTakerStateProvider } from './components/ProseMirror/NoteTakerState';

const App = () => {
  const [globalState, setGlobalState] = useState({ errors: [] });
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ChakraProvider theme={theme}>
      <Router>
      <AuthContext.Provider value={{ userData, setUserData, token, setToken, isLoading, setIsLoading }}>
          <ErrorHandler />
          <NoteTakerStateProvider>
          <AuthCheck>
              <Header/>
            <Routes>
              <Route path="/" element={<Home globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/logout" element={<Logout globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/analysis_history" element={<AnalysisHistory globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dispute_analysis" element={<QuestionAnswering globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dispute_analysis/:caseID" element={<MultiDocResults globalState={globalState} setGlobalState={setGlobalState} />} />
              <Route path="/dispute_analysis_new/:caseID" element={<DisputeAnalysisResults globalState={globalState} setGlobalState={setGlobalState} />} />
              
              <Route path="*" element={<><h1> Page not found</h1></>} />
            </Routes>
          </AuthCheck>
          </NoteTakerStateProvider>
        </AuthContext.Provider>
      </Router>
    </ChakraProvider>
  );
};


const ErrorHandler = () => {
  const { globalState, setGlobalState } = useContext(AuthContext);
  
  useEffect(() => {
    console.log("ERRORS", globalState)
    if (globalState?.errors && globalState?.errors?.length > 0) {
      globalState.errors.forEach((error) => {
        alert(error.message);
      });
      setGlobalState((prevState) => ({ ...prevState, errors: [] }));
    }
  }, [globalState, setGlobalState]);

  return null;
};

const AuthCheck = ({ children }) => {
  const { userData, setUserData, token, setToken, isLoading, setIsLoading } = useContext(AuthContext);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndUserData = async () => {
      const tokenFromCookie = getCookie('auth-token');
      const exemptRoutes = ['/logout', '/'];
      if (!exemptRoutes.includes(window.location.pathname)) {
        if (tokenFromCookie) {
          setToken(tokenFromCookie);
          axios.defaults.headers.common['auth-token'] = tokenFromCookie;
          setIsLoading(false);
        } else {
          navigate('/logout');
        }
      }
    };

    fetchTokenAndUserData();
  }, [navigate, setIsLoading]);

  return <>{children}</>;
};

export default App;
