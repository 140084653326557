import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Tooltip } from '@chakra-ui/react';

import SidebarOpen from '../../images/panel-open.svg'
import SidebarClose from '../../images/panel-close.svg'

import { renderAsync } from 'docx-preview';

// Animation timing constants
const EXPAND_DURATION = 0.25;
const COVER_DURATION = 0.15;

const DocxPreview = ({
  isExpanded,
  onToggle,
  documents,
  docPreviewRef,
  previewSelectedDocID,
  setPreviewSelectedDocID,
  currentTab
}) => {
  const [isCoverVisible, setIsCoverVisible] = useState(!isExpanded);

  useEffect(() => {
    if(isExpanded) setIsCoverVisible(false)
  }, [isExpanded])

  const handleToggle = () => {
    if (isExpanded) {
      setIsCoverVisible(true);
      setTimeout(() => {
        onToggle();
      }, COVER_DURATION * 1000);
    } else {
      onToggle();
      setTimeout(() => {
        setIsCoverVisible(false);
      }, EXPAND_DURATION * 1000);
    }
  };


  useEffect(() => {
    if (documents?.length > 0) {
      const firstDoc = documents[0];
      setPreviewSelectedDocID(firstDoc.id);
      if (docPreviewRef.current) {
        renderAsync(firstDoc.file, docPreviewRef.current, null, {
          ignoreLastRenderedPageBreak: false
        }).catch(err =>
          console.error(err)
        );
      }
    } else {
      console.log('NO DOCX FOUND');
    }
  }, [documents]);



  useEffect(() => {
    if (documents?.length && previewSelectedDocID) {
      const selectedDoc = documents.find(
        doc => doc.id === previewSelectedDocID
      );
      if (selectedDoc && docPreviewRef.current) {
        setPreviewSelectedDocID(selectedDoc.id);
        renderAsync(selectedDoc.file, docPreviewRef.current).catch(err =>
          console.error(err)
        );
      }
    }
  }, [previewSelectedDocID, documents]);

  return (<div className='doc-sidebar'>
    <motion.div
      className={`doc-preview ${currentTab}`}
      initial={{ width: '5%' }}
      animate={{ width: isExpanded ? '40%' : '5%' }}
      transition={{ duration: EXPAND_DURATION, ease: 'easeInOut' }}
      style={{
      }}
    >
      <AnimatePresence>
        {isCoverVisible && (
          <motion.div
            className="content-cover"
            initial={{ width: isExpanded ? '0%' : '100%' }}
            animate={{ width: '100%' }}
            exit={{ width: '0%' }}
            transition={{ duration: COVER_DURATION }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              background: '#F7F5F0',
              zIndex: 1,
            }}
          />
        )}
      </AnimatePresence>
      <Box
        className="file-select-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {documents.map(doc => (
          <div
            className={`file-select ${
              doc.id === previewSelectedDocID ? 'selected' : ''
            }`}
            key={doc.id}
            onClick={() => setPreviewSelectedDocID(doc.id)}
          >
            {doc.file.name}
          </div>
        ))}
      </Box>
      <Box
        ref={docPreviewRef}
        className="doc-preview-container"
        height="100%"
        overflowY="auto"
        style={{ position: 'relative', zIndex: 0, opacity: isExpanded ? 1 : 0 }}
      ></Box>
      <div className={`sidebar-toggle${isExpanded ? ' expanded' : ''}`} onClick={handleToggle}>
        <Tooltip label={isExpanded ? 'Minimize document preview' : 'Maximize document preview'}>
          <img src={isExpanded ? SidebarClose : SidebarOpen} alt="" />
        </Tooltip>
      </div>
    </motion.div>
    </div>
  );
};

export default DocxPreview;
