import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Flex,
  Input,
  Stack,
  Button,
  FormControl,
  FormHelperText,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import ButtonForwardIcon from './../../components/ButtonForwardIcon';
import { setCookie, getCookie } from './../../helpers/cookies';
import './index.scss';

import AdidemLogoFull from '../../images/adidem-logo-full.svg'

const Home = () => {
  const navigate = useNavigate();
  
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isOtpSend, setIsOtpSent] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState(null);
  const [otpRequestCount, setOtpRequestCount] = useState(0);
  const [isRequestDisabled, setRequestDisabled] = useState(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (otpRequestCount > 0) {
      setRequestDisabled(true);
      timeoutId = setTimeout(() => setRequestDisabled(false), 300000); // 5 minutes timeout
    }
    return () => clearTimeout(timeoutId);
  }, [otpRequestCount]);

  useEffect(() => {
    const doesAuthCookieExist = getCookie('auth-token');
    if(doesAuthCookieExist) {
      navigate('/dispute_analysis')
    }
  }, [])

  const handleEmailChange = e => {
    setEnteredEmail(e.target.value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

const handleLoginClick = async () => {
    setIsOtpLoading(true);
    if (!emailError && enteredEmail !== '' && otpRequestCount < 3) {
      setOtpRequestCount(prevCount => prevCount + 1);
      try {
        const url = `${process.env.REACT_APP_BASE_PATH}/v1/send_otp`;
        const payload = {
          email: enteredEmail,
        };
        const response = await axios.post(url, payload);
        console.log('RESPONSE', response.data);
        setIsOtpSent(true);
        setIsOtpLoading(false);
      } catch (e) {
        console.log('EEEE', e?.status, e?.message, e?.code);
      }
    } else if (otpRequestCount > 4) {
      alert('You have reached the maximum number of OTP requests.');
    }
  };

  const handleOtpVerifyClick = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_PATH}/v1/verify_otp`;
      const payload = {
        email: enteredEmail,
        otp: enteredOtp,
      };
      const response = await axios.post(url, payload);
      const { message, token } = response.data;
      if(message === 'OTP verified successfully') {
        axios.defaults.headers.common['auth-token'] = token;
        setCookie('auth-token', token, 30);
        navigate("/dispute_analysis")
      }
    } catch (e) {
      if(e?.response?.status) {
        setIsOtpInvalid(true);
      }
    }
  };

  const RenderEmailLogin = () => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !emailError && enteredEmail !== '') {
        handleLoginClick();
      }
    };
  
    return (
      <Box width={'100%'} maxWidth={'450px'}>
        <Text fontSize="2em" fontFamily={'Garnett'} fontWeight={600} color={'#000'}>Welcome to Consensus</Text>
        <Text fontSize="1em" marginBottom={12}>Dispute Intelligence platform to accelerate case comprehension and resolution</Text>
        <Stack spacing={6}>
          <FormControl isInvalid={emailError}>
            <Input
              variant={'default_highlighted'}
              type="email"
              placeholder="Email"
              value={enteredEmail}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              isInvalid={emailError}
              errorBorderColor="red.300"
            />
            <FormHelperText mt={4} mb={4}>
              By signing up to the service, you agree to be subject to our
              privacy policy.
            </FormHelperText>
          </FormControl>
          <Button
            isLoading={isOtpLoading}
            variant={'default'}
            rightIcon={<ButtonForwardIcon />}
            onClick={handleLoginClick}
            p={6}
            isDisabled={emailError || enteredEmail === ''}
          >
            Get started
          </Button>
        </Stack>
      </Box>
    );
  };
  
  const RenderOtpInput = () => {
    return (
      <Box width={'100%'} maxWidth={'300px'}>
        <Stack spacing={4}>
          <Text mb={4}>Please confirm the OTP sent to <Text as={'b'} textDecoration={'underline'} fontWeight={700}>{enteredEmail}</Text></Text>
        {isOtpInvalid ? <Text color={'red.500'} fontWeight={500}>Entered OTP is invalid or has expired</Text> : <></>}
          <FormControl>
            <HStack>
              <PinInput isInvalid={isOtpInvalid} otp size="lg" onChange={e => setEnteredOtp(e)}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <FormHelperText>
              {isRequestDisabled ? (
                'You can request a new OTP in a few minutes'
              ) : (
                <>
                  Did not receive the OTP?{' '}
                  <u onClick={handleLoginClick}>Resend it</u>
                </>
              )}
            </FormHelperText>
          </FormControl>
          <Button
            variant={'default'}
            color={'white'}
            onClick={handleOtpVerifyClick}
            mt={4}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
      <Flex className='login' w={'100%'}>
        <Box className="login-left-container">
          <VStack spacing={2} maxWidth={'66%'}>
          </VStack>
        </Box>
        <Flex className='login-right-container'>
          <img className='adidem-logo-full' alt='' src={AdidemLogoFull}></img>
          {isOtpSend ? RenderOtpInput() : RenderEmailLogin()}
        </Flex>
      </Flex>
  );
};

export default Home;
