import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactFlow, { Background } from 'reactflow';
import 'reactflow/dist/style.css';
import './index.scss';
import EventNode from './EventNode';

const VisualTimeline = ({ timelineData, goToDoc, filterMode }) => {
  const [nodes, setNodes] = useState([]);
  const nodeRefs = useRef([]);

  useEffect(() => {
    const filteredData = filterTimelineData(timelineData, filterMode);
    const heights = nodeRefs.current.map(ref => ref?.getHeight() || 0);
    const positions = heights.map((height, index) => {
      const previousHeight = heights
        .slice(0, index)
        .reduce((acc, h) => acc + h + 50, 0);
      return { x: 0, y: previousHeight };
    });

    const newNodes = filteredData.map((event, index) => ({
      id: `event-${index}`,
      type: 'custom',
      data: {
        date: event.date,
        description: event.description,
        references: event.references,
        type: event.type,
        tags: event.tags,
      },
      position: positions[index],
    }));

    setNodes(newNodes);
  }, [timelineData, filterMode]);

  const filterTimelineData = (data, filters) => {
    if (!filters || filters.length === 0) {
      return data;
    }

    return data.filter(event => {
      const eventType = event.category.toLowerCase();
      return filters.some(filter => eventType.includes(filter.toLowerCase()));
    });
  };

  const nodeTypes = useMemo(() => ({ custom: EventNode }), []);

  const renderArrow = () => {
    const totalHeight = nodeRefs.current.reduce(
      (acc, ref) => acc + (ref?.getHeight() || 0) + 50,
      0
    );
    const arrowStartY = -500;
    const arrowEndY = totalHeight;

    return (
      <svg className="timeline-arrow">
        <line
          x1="180px"
          y1={arrowStartY}
          x2="180px"
          y2={arrowEndY}
          style={{ markerEnd: 'url(#arrowhead)' }}
        />
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" fill="black" />
          </marker>
        </defs>
      </svg>
    );
  };

  const goToReference = (reference) => {
    goToDoc(reference.doc_id, reference.statement);
  };

  const filteredTimelineData = filterTimelineData(timelineData, filterMode);

  return (
    <div className="timeline-container">
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{ padding: 0.2 }}
        panOnScroll
        zoomOnScroll
        style={{ width: '100%', height: '100%' }}
        proOptions={{ hideAttribution: true }}
      >
        <Background />
      </ReactFlow>
      {filteredTimelineData.map((event, index) => (
        <EventNode
          key={index}
          ref={el => (nodeRefs.current[index] = el)}
          data={event}
          goToReference={goToReference}
        />
      ))}
      {renderArrow()}
    </div>
  );
};

export default VisualTimeline;