import React from "react"

const AuditHistoryPageIcon = ({ isActive }) => {
    const fillColour = isActive ? '#004732' : '#979797'
    return (<svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1014_932)">
    <path d="M18.0926 17.627L13.8093 13.29C13.6254 13.1038 13.5203 12.8643 13.5203 12.5982V6.62483C13.5072 6.42527 13.6648 6.26562 13.8619 6.26562H15.0838C15.2809 6.26562 15.4385 6.42527 15.4385 6.62483V12.1991L19.459 16.2568C19.6035 16.4031 19.6035 16.6293 19.459 16.7756L18.605 17.6403C18.4604 17.7867 18.2371 17.7867 18.0926 17.6403V17.627Z" fill={fillColour}/>
    <path d="M15.1489 0C12.3766 0 9.68321 0.984479 7.55474 2.78049L9.15766 4.72284C10.8394 3.31264 12.9547 2.52772 15.1489 2.52772C20.3124 2.52772 24.5036 6.77162 24.5036 12C24.5036 17.2284 20.3124 21.4723 15.1489 21.4723C9.9854 21.4723 5.79416 17.2284 5.79416 12C5.79416 11.5743 5.82044 11.1619 5.88613 10.7361L7.05547 12.7184L9.19708 11.4146L5.75474 5.58758L0 9.09978L1.28759 11.2683L3.50803 9.92461C3.38978 10.6164 3.31095 11.3082 3.31095 12C3.31095 18.612 8.63212 24 15.162 24C21.692 24 27.0131 18.612 27.0131 12C27.0131 5.38803 21.692 0 15.1489 0Z" fill={fillColour}/>
    </g>
    <defs>
    <clipPath id="clip0_1014_932">
    <rect width="27" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    )
}

export default AuditHistoryPageIcon;
